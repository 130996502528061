.banner-content-container {
    padding: 50px 0 30px;
    margin: 0 auto;
    max-width: 800px;
}
@media screen and (max-width: 700px) {
    .banner-container {
        background-color: #f5f5f5;
    }
    .banner-content-container {
        padding: none;
    }
}