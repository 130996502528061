.square-img {
    width: 300px;
    margin: 0 20px 20px 0;
}

.square-empty {
    width: 300px;
    height: 300px;
    margin: 0 20px 20px 0;
    display: inline-block;
}
.square-two {
    width: 60%;
}

.align-start {
    align-items: flex-start;
}
.grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.grid-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
/* .md-container {
    max-width: 100%;
} */