* {
  font-family: 'Nunito', sans-serif;
}
p {
  font-size: 16px;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  padding-bottom: 100px;
}
.eyebrow {
  background-color: #444;
  width: 50px;
  height: 4px;
  margin: 10px 0;
}

.page-heading {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 600;
  font-size: 60px;
}
.page-subheading {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 600;
  font-size: 40px;
}
.heading-font {
  font-family: 'Cormorant Garamond', serif;
}

.section-container {
  padding: 50px 30px;
  position: relative;
}
.fixed-container {
  width: 100%;
  max-width: 800px;
  position: relative;
  display: block;
  margin: 0 auto;
}

.fixed-container-lg {
  width: 100%;
  max-width: 1000px;
  position: relative;
  display: block;
  margin: 0 auto;
}
.btn-like-link {
  border: 2px #444 solid;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: transparent;
  letter-spacing: 0.8px;
  font-weight: 700;
  color: #444;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
}
.btn-like-link:hover {
  border-radius: 20px;
  padding: 10px 20px;
  border-color: #000;
  color: #000;
}
@media screen and (max-width: 700px) {
  .btn-like-link {
    padding: 5px 10px;
    margin-top: 15px;
  }
}

a {
  color: #222;
  transition: 0.3s;
}
a:hover {
  color: #fff !important;
  font-weight: 700;
  background-color: #444;
  text-decoration: none;
  padding: 0 2px;
}
.bg-link {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.sm-margin-horizontal {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100%;
}
.md-margin-horizontal {
  margin-left: 25px;
  margin-right: 25px;
  max-width: 100%;
}
.sm-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}
.md-margin {
  margin-top: 25px;
  margin-bottom: 25px;
}
.lg-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}
.fit-img-container {
  width: 99%;
  max-height: 100%;
}