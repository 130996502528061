.navbar {
    padding: 20px 30px 30px;
    text-align: left;
}

.navlink {
    margin: 0 20px;
    text-decoration: none;
    font-weight: 700;
    color: #444;
    font-size: 20px;
    white-space: nowrap;
    display: inline-block;
}

.navlink.active {
    border-bottom: 5px #444 solid;
    color: #444;
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 20px 15px 30px;
    }
    .navlink {
        margin: 0 15px;
    }
}