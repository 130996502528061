
footer {
    margin-top: 30px;
    padding: 0 40px 20px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #333;
}

footer > div {
    font-size: 18px;
}
.footer-icon {
    /* ugh webkit */
    color: #333 !important; 
    font-size: 18px;
}

@media screen and (max-width: 630px) {
    footer {
        padding: 70px 15px 20px;
    }
}