.row {
    align-content: center;
    text-align: left;
    margin: 20px 0;
}
.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 30px;
    flex-flow: row wrap;
}
.v-center {
    align-items: center;
}
.rowname {
    font-family: 'Cormorant Garamond', serif;
    font-size: 30px;
    width: 160px;
    margin-bottom: 30px;
}
.entry {
    max-width: 800px;
    margin-bottom: 30px;
    margin-left: 20px;
}
.entry > p, li {
    font-size: 18px;
}
.entry-header {
    display: flex;
}
.entry-logo {
    max-width: 75px;
    margin-right: 20px;
}
.entry-logo > img {
    width: 100%;
}
.entry-name {
    font-weight: 800;
    font-size: 25px;
    font-family: 'Cormorant Garamond', serif;
}
.entry-subtext {
    margin: 7px 0;
    font-size: 18px;
    font-weight: 400;
}
p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}
.entry-progression {
    margin: 15px 0;
    margin-left: 100px;
}
.title-container { 
    margin: 10px 0;
}
.title {
    font-size: 20px;
    font-weight: 800;
}
.years {
    font-size: 16px;
}
.skills {
    font-size: 20px;
    padding: 0;
    margin-top: 0;
}
.skills > li {
    margin-bottom: 5px;
    list-style-type: none;
}
.one-liner {
    font-size: 20px;
    margin-top: 7px;
}

@media screen and (min-width: 450px) {
    .narrow-visual-guide {
        display: none;
    }
}
@media screen and (max-width: 1050px) {
    .rowname {
        margin: 0 30px 30px 0;
    }
    .entry, .entries {
        width: 100%;
    }
}
@media screen and (max-width: 450px) {
    .rowname {
        margin-top: 20px;
    }
    .entry-progression {
        margin-left: 0;
    }    
    .entry-logo {
        display: none;
    }
}